import { CancelToken } from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store, { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import {
  IOrdersFilter, ISearchPackagesFilter, ManagerCoursesPackagesSearchGetRequest,
  ManagerOrderLargeResource,
  ManagerOrdersGetRequest,
  ManagerOrderShortResourcePaginator,
  ManagerOrdersOrderIdDeleteRequest,
  ManagerOrdersOrderIdGetRequest,
  ManagerOrdersOrderIdPatchRequest,
  ManagerOrdersOrderIdStatusStatusPatchRequest,
  ManagerOrdersPostRequest, ManagerOrdersPricesGetParams, ManagerOrdersPricesGetRequest,
  ManagerOrderStoreRequest, ManagerSalesCustomGetParams, ManagerSalesCustomGetRequest, NameValueChildrenResource,
  OrderStatus,
} from '@/store/types'
import { tableOptionsToParams } from '@/utils/functions'

/**
 * Работа с заказами
 * - получение списка
 * - просмотр, редактирование, смена статуса, удаление
 */

@Module({
  dynamic: true,
  name: 'ManagerOrders',
  namespaced: true,
  store,
})
class ManagerOrders extends VuexModule {
  // ---------------------------- Orders ---------------------------- >>
  // Filter
  ordersFilter: IOrdersFilter = GET_DEFAULT_TABLE_FILTER()
  totalOptions: NameValueChildrenResource[] = []

  @Mutation
  setOrdersFilter (payload: IOrdersFilter) {
    this.ordersFilter = Object.assign({}, payload)
  }

  @Mutation
  setTotalOptions(payload: NameValueChildrenResource[]) {
    this.totalOptions = payload
  }

  // Entities
  orders: ManagerOrderShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  userOrders: ManagerOrderShortResourcePaginator = GET_DEFAULT_PAGINATOR()

  @Mutation
  setOrders (payload: ManagerOrderShortResourcePaginator) {
    this.orders = Object.assign({}, payload)
  }

  @Mutation
  setUserOrders (payload: ManagerOrderShortResourcePaginator) {
    this.userOrders = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchOrders (payload: { cancelToken?: CancelToken, filter?: IOrdersFilter }) {

    const { data } = await ManagerOrdersGetRequest(tableOptionsToParams(payload.filter || this.ordersFilter), { cancelToken: payload.cancelToken })
    payload.filter ? this.setUserOrders(data) : this.setOrders(data)
    return data
  }

  // ---------------------------- Order ---------------------------- >>

  order: ManagerOrderLargeResource | null = null

  @Mutation
  setOrder (payload: ManagerOrderLargeResource) {
    this.order = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchOrder (orderID: number) {
    const { data } = await ManagerOrdersOrderIdGetRequest(orderID)
    this.setOrder(data)
    return data
  }

  // ---------------------------- Order manage ---------------------------- >>

  @Action({ rawError: true })
  async saveOrder (payload: { orderID?: number, params: ManagerOrderStoreRequest }) {
    if (payload.orderID) {
      const { data } = await ManagerOrdersOrderIdPatchRequest(payload.orderID, payload.params)
      this.setOrder(data)
      return data
    } else {
      const { data } = await ManagerOrdersPostRequest(payload.params)
      this.setOrder(data)
      return data
    }
  }

  @Action({ rawError: true })
  async getDiscountsCustomOrder(payload: ManagerSalesCustomGetParams) {
    const { data } = await ManagerSalesCustomGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async changeOrderStatus (payload: { orderID: number, params: OrderStatus }) {
    const { data } = await ManagerOrdersOrderIdStatusStatusPatchRequest(payload.orderID, payload.params)
    this.setOrder(data)
    return data
  }

  @Action({ rawError: true })
  async deleteOrder (orderID: number) {
    await ManagerOrdersOrderIdDeleteRequest(orderID)
  }

  @Action({ rawError: true })
  async fetchOrderPrices(payload: ManagerOrdersPricesGetParams) {
    const { data } = await ManagerOrdersPricesGetRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async searchPackages(payload: ISearchPackagesFilter) {
    const { data } = await ManagerCoursesPackagesSearchGetRequest(tableOptionsToParams(payload))
    return data
  }
}

const ManagerOrdersModule = getModule(ManagerOrders)

export default ManagerOrdersModule
